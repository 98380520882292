import React, { Component } from "react"
import Headermenu from "../containers/RealestatePage1/HeaderMenuBS"
import Banner from "../containers/RealestatePage1/Banner"
import FeatureCards from "../containers/RealestatePage1/FeatureCards"
import Properties from "../containers/RealestatePage1/Properties"
import OurStats from "../containers/RealestatePage1/OurStats"
import Gallery from "../containers/RealestatePage1/Gallery"
import AboutUs from "../containers/RealestatePage1/AboutUs"
import Testimonials from "../containers/RealestatePage1/Testimonials"
import Amenities from "../containers/RealestatePage1/Amenities"
import Footer from "../containers/RealestatePage1/Footer"
import GlobalStyle from "../containers/RealestatePage1/Common/global-styles"
import Fonts from "../containers/RealestatePage1/Common/fonts"
import "../components/layout.css"
import SEO from "../components/seo"
import BuyNow from "../components/BuyNow"

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

class RealestatePage1 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpenLightBox: false,
      lightBoxImages: [],
      photoIndex: 0,
    }
    this.openLightBox = this.openLightBox.bind(this)
    this.openLightBoxAbout = this.openLightBoxAbout.bind(this)
  }

  openLightBox(imageArray, idx) {
    const images = []
    for (const [index, value] of imageArray.Images.entries()) {
      console.log(index)
      images.push(value.Image.childImageSharp.fluid.src)
    }

    this.setState({
      isOpenLightBox: true,
      lightBoxImages: images,
      photoIndex: idx,
    })
  }

  openLightBoxAbout(imageArray, idx) {
    const images = []
    for (const [index, value] of imageArray.SliderData.entries()) {
      console.log(index)
      images.push(value.Img.childImageSharp.fluid.src)
    }
    this.setState({
      isOpenLightBox: true,
      lightBoxImages: images,
      photoIndex: idx,
    })
  }

  render() {
    return (
      <div>
        {
          <div>
            <Fonts />
            <GlobalStyle />
            <main>
              <SEO
                title="Real Estate Page 1 | Gatsby Landing Page Templates"
                description="Buy unique and professional React GatsbyJS Real Estate landing page templates from Topaz"
              />
              <BuyNow />
              <Headermenu />
              <Banner />
              <FeatureCards />
              <Properties />
              <OurStats />
              <Amenities />
              <Gallery openLightBox={this.openLightBox} />
              <AboutUs openLightBoxAbout={this.openLightBoxAbout} />
              <Testimonials />
            </main>
            <Footer />
          </div>
        }
        {this.state.isOpenLightBox && (
          <Lightbox
            mainSrc={this.state.lightBoxImages[this.state.photoIndex]}
            nextSrc={
              this.state.lightBoxImages[
                (this.state.photoIndex + 1) % this.state.lightBoxImages.length
              ]
            }
            prevSrc={
              this.state.lightBoxImages[
                (this.state.photoIndex + this.state.lightBoxImages.length - 1) %
                  this.state.lightBoxImages.length
              ]
            }
            onCloseRequest={() => this.setState({ isOpenLightBox: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (this.state.photoIndex +
                    this.state.lightBoxImages.length -
                    1) %
                  this.state.lightBoxImages.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex:
                  (this.state.photoIndex + 1) %
                  this.state.lightBoxImages.length,
              })
            }
          />
        )}
      </div>
    )
  }
}

export default RealestatePage1
